*{font-family: 'Poppins', sans-serif !important;margin:0;padding:0;}
p, ul{margin:0;padding:0;list-style-type:none;}
*::-webkit-scrollbar {width:8px;}
*::-webkit-scrollbar-thumb {background-color: #B1B1B1;outline:0;border-radius:8px;}
/* .formsection::-webkit-scrollbar-track {} */
*::-ms-scrollbar {width:8px;}
*::-ms-scrollbar-thumb {background-color: #B1B1B1;outline:0;border-radius:8px;}
body{background-color: #F5F5F5 !important;}
p, .MuiTypography-p{white-space: pre-line;}
span, .MuiTypography-span{white-space: pre-line;}

.custom_container{width: 100%;max-width:1200px;margin: 0 auto;font-family: 'Poppins', sans-serif;}

.appLoader{position: fixed;height: 100%;width: 100%;z-index: 999;top: 0;left: 0;display: flex;justify-content: center;align-items: center;}

.border_google{border: 1px solid #DEE2ED;}
.border_facebook{border: 1px solid #DEE2ED}
.border_email{border:1px solid #DEE2ED;}
label{color:#03A6A6;font-size:12px;margin:10px 0 5px 0;}
.formControl {margin:0 0 15px 0 !important;}
.MuiFormControl-root{z-index:1;}
.formControl fieldset{background-color:rgba(255, 255, 255, 0.5) !important;border:1px solid #C2C9D1 !important;border-radius:8px !important;z-index:-1;height:44px !important;}
.formControl input{z-index:2 !important;height:40px !important;padding:0 15px !important;font-size:14px !important;}
.MuiSelect-select{padding:6px 15px !important;font-size:14px !important;height:40px !important;}
.shortby .MuiSelect-select{line-height: 40px;}/*margin:0;padding: 0 15px !important;*/
.css-mhc70k-MuiGrid-root{margin-top:0 !important;margin-left:0 !important;width:100% !important;}

.default_btn span{margin:0;}
.default_btn{font-family: 'Poppins', sans-serif !important;background-color:#03A6A6 !important;border-radius:4px;color:#FFF !important;text-transform:capitalize !important;height:35px !important;padding:0 15px !important;line-height:0 !important;font-weight: 400 !important;}
.default_btn:hover{background-color:#004949 !important;}
.default_btn_border{border:1px solid #03A6A6 !important;font-family: 'Poppins', sans-serif !important;background-color:transparent !important;border-radius:4px;color:#03A6A6 !important;text-transform:capitalize !important;height:35px !important;padding:0 15px !important;line-height:0 !important;font-weight: 400 !important;}
.default_btn_border:hover{background-color:#03A6A6 !important;color:#FFF !important;}

.form_buttons{display:flex;justify-content:center;}
.Mui-disabled{background: linear-gradient(0deg, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)), #C2C9D1;color:rgba(0,0,0,0.5);}

div.Mui-error fieldset{border:1px solid rgba(255,0,0,0.3) !important}
p.Mui-error{font-family: 'Open Sans', sans-serif !important;color:#00000080 !important;font-size: 12px !important;font-weight: 400 !important;margin:4px 0 0 0 !important;position:relative;}
p.Mui-error:before {font-family: "Material Icons";content: "\e002";color:#F03738 !important;margin:0 5px 0 0;font-size:17px !important;float:left !important;line-height:19px !important;letter-spacing: 0;}

.MuiTab-root{text-transform: capitalize !important;font-weight: 400 !important;padding: 0 !important;color: #333333 !important;}
.MuiTabs-indicator{background-color:#03A6A6 !important}

/* Select Type to view data */

.type_of_view{margin:0 35px 0 0 !important;float:right;}
.type_of_view .MuiSelect-select{display: flex;justify-content: center;align-items: center;padding:0 52px 0 20px !important;color: #000;font-size:16px !important;font-weight:500 !important;height:40px !important;}
.type_of_view .MuiSelect-select svg{font-size:20px;margin:0 15px 0 0;color: #000;}
.type_of_view fieldset{border-radius:50px;background: rgba(137, 200, 56, 0.2);}
.type_of_view_list .MuiTypography-p{font-weight: 500;font-size: 16px;line-height: 20px;color: #000000;}
.type_of_view_list .MuiTypography-p .MuiTypography-span{display:none;}
.type_of_view_list_item{border-left:2px solid transparent !important;min-width:220px;max-width:220px;}
.type_of_view_list_item.Mui-selected{border-left:2px solid #03A6A6 !important;}
.type_of_view_list_item{padding:10px 15px !important;align-items: flex-start !important;justify-content: flex-start !important;}
.type_of_view_list_item .MuiTypography-p{font-weight: 500 !important;font-size: 14px !important;line-height: 21px !important;color: #4B4B4B;padding:0 0 0 15px !important;}
.type_of_view_list_item .MuiTypography-p .MuiTypography-span{font-size: 12px !important;line-height: 14px !important;color: #6F6F8B !important;display: block !important;font-weight:400 !important;margin:2px 0 0 0 !important;}

.type_of_view img{margin:0 15px 0 5px;width:21px;height:21px;}
.type_of_view_list_item img{margin:2px 0 0 0;width:16px;height:16px;}

.type_of_view .selected_private .MuiSelect-select{border-radius: 50px;position:relative;background-color:#FFF;overflow:visible;}
.type_of_view .selected_private .MuiSelect-select:before{border-radius: 50px;z-index: -1;position:absolute;top:-1px;left:-1.5px;width:102%;height:105%;content:"";background: linear-gradient(98.31deg, #03A6A6 16.08%, #89C838 101.26%);}
.type_of_view .selected_private fieldset{border:0;background: rgba(137, 200, 56, 0.2);height:auto;}

.type_of_view .selected_friends .MuiSelect-select{border-radius: 50px;position:relative;background-color:#FFE1B8;overflow:visible;}
.type_of_view .selected_friends .MuiSelect-select:before{border-radius: 50px;z-index: -1;position:absolute;top:-1px;left:-1.5px;width:102%;height:105%;content:"";background:#F2994A;}
.type_of_view .selected_friends fieldset{border:0;background:transparent;height:auto;}

.type_of_view .selected_public .MuiSelect-select{border-radius: 50px;position:relative;background-color:#C9DFFF;overflow:visible;}
.type_of_view .selected_public .MuiSelect-select:before{border-radius: 50px;z-index: -1;position:absolute;top:-1px;left:-1.5px;width:102%;height:105%;content:"";background:#2D9CDB;}
.type_of_view .selected_public fieldset{border:0;background:transparent;height:auto;}

.type_of_view .selected_restricted .MuiSelect-select{border-radius: 50px;position:relative;background-color:#FFC8C7;overflow:visible;}
.type_of_view .selected_restricted .MuiSelect-select:before{border-radius: 50px;z-index: -1;position:absolute;top:-1px;left:-1.5px;width:102%;height:105%;content:"";background:#EB5757;}
.type_of_view .selected_restricted fieldset{border:0;background:transparent;height:auto;}

.type_of_view_list_item_private .MuiTypography-p{color:#27AE60 !important;}
.type_of_view_list_item_private .selected_img2{display:none;}

.type_of_view_list_item_friends .MuiTypography-p{color:#2D9CDB !important;}
.type_of_view_list_item_friends .selected_img2{display:none;}

.type_of_view_list_item_public .MuiTypography-p{color:#EB5757 !important;}
.type_of_view_list_item_public .selected_img2{display:none;}

.type_of_view_list_item_restricted .MuiTypography-p{color:#F2994A !important;}
.type_of_view_list_item_restricted .selected_img2{display:none;}

.type_of_view .selected_private .MuiSelect-select .selected_img1{display:none !important;}
.type_of_view .selected_friends .MuiSelect-select .selected_img1{display:none !important;}
.type_of_view .selected_public .MuiSelect-select .selected_img1{display:none !important;}
.type_of_view .selected_restricted .MuiSelect-select .selected_img1{display:none !important;}

.button_dailog_heading .MuiTypography-h2{color:#000000;font-size:18px;font-weight:500;padding:10px 0 0 0;display:inline-block;}
.button_dailog_heading button{margin:9px 0 0 0 !important}

.MuiAlert-standardError{border-radius:4px !important;background-color:rgba(236, 92, 92, 0.25) !important;}
.MuiAlert-standardError .MuiAlert-icon{position:relative;align-items: center;margin:0 6px 0 0;}
.MuiAlert-standardError .MuiSvgIcon-root{display:none;}
.MuiAlert-standardError .MuiAlert-icon:before {font-family: "Material Icons";content: "\e002";color:#EB5757 !important;margin:0 5px 0 0;font-size:20px !important;float:left !important;line-height:19px !important;letter-spacing: 0;}
.MuiAlert-standardError .MuiAlert-message{color:#4B4B4B;font-size:14px;line-height:19px;}
.MuiAlert-standardError .MuiTypography-root{margin:0;}

.MuiAlert-filledError .MuiAlert-message{color:#4B4B4B;font-size:14px;line-height:19px;}
.MuiAlert-filledError .MuiSvgIcon-root{display:none;}
.MuiAlert-filledError .MuiTypography-root{margin:0;}
.MuiAlert-filledError .MuiAlert-icon:before {font-family: "Material Icons";content: "\e002";color:#EB5757 !important;margin:0 5px 0 0;font-size:20px !important;float:left !important;line-height:19px !important;letter-spacing: 0;}

/* .MuiGrid-root{margin: 0 !important;width: 100% !important;} */

/* .hydrated img{width:100%;height:100% !important;overflow:hidden !important;} */
/* .amplifyImageImport img{width:100% !important;} */

textarea{width:calc(100% - 30px) !important;max-height:150px !important;overflow-y: scroll !important;}

amplify-s3-image{--width:100% !important;display: flex !important;justify-content: center !important;align-items: center !important;}/*--height:100% !important;*/
.my-anchor-css-class{color: #03a6a6!important;text-decoration: inherit !important;}

@media only screen and (min-width:0px) and (max-width:767px){
    .type_of_view{float: none;display: block !important;}
    .type_of_view .MuiSelect-select{height:34px !important;}
    .type_of_view fieldset{height:36px;}

    .formControl{margin:0 0 10px 0 !important;}
    .MuiGrid-root{margin: 0 !important;width: 100% !important;}

    .type_of_view .MuiSelect-select{padding:0 30px 0 5px !important;}
    .type_of_view_list .MuiTypography-p{font-size:12px;}
    .type_of_view img{width: 14px;height: 14px;margin:0 5px;}
    .basic_dialog.medium_dialog .type_of_view .MuiSelect-select{height: 26px !important;}
}
@media only screen and (min-width:0px) and (max-width:1024px){

.type_of_view_list_item {}
.type_of_view{margin:5px 35px 0 0 !important;}
.button_dailog_heading button{margin:9px 0 0 0 !important;}
}


/* customSwitch */
.customSwitch .MuiSwitch-thumb{box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25) !important;}
.customSwitch .MuiSwitch-track{background:#D3D4DA !important;}
.customSwitch .Mui-checked .MuiSwitch-thumb{background-color: #03A6A6 !important;box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25) !important;}
.customSwitch .Mui-checked+.MuiSwitch-track{background-color:#9bdcdc !important;}

.MuiSnackbar-root{top: 24px !important;left: 50% !important;right: auto !important;transform: translateX(-50%) !important;bottom: auto !important;}
.MuiAlert-filledSuccess{background-color:#55B938 !important;}
.MuiAlert-filledError.MuiAlert-filled{background-color:#F00 !important;}
.MuiAlert-filledError.MuiAlert-filled .MuiAlert-message{color:#FFF;font-size:14px;line-height:19px;}
/* .MuiAlert-filledError.MuiAlert-filled .MuiSvgIcon-root{display:none;} */
/* .MuiAlert-filledError.MuiAlert-filled .MuiTypography-root{margin:0;} */
.MuiAlert-filledError.MuiAlert-filled .MuiAlert-icon:before {font-family: "Material Icons";content: "\e002";color:#FFF !important;margin:0 5px 0 0;font-size:20px !important;float:left !important;line-height:19px !important;letter-spacing: 0;}

@media only screen and (min-width:0px) and (max-width:1024px){
    .MuiSnackbar-root{width: calc(100% - 20px) !important;}
}

.mobile_show{display:none;}
.mobile_hide{display:block;}
@media only screen and (min-width:0px) and (max-width:1024px){
    .mobile_show{display:block;}
    .mobile_hide{display:none;}
}

.disableFuture .MuiOutlinedInput-root{position:relative;}
.disableFuture .MuiOutlinedInput-root::after{position:absolute;top:1px;left:2px;height:calc(100% - 2px);width:calc(100% - 60px);background-color:transparent;content:"";}