.customLoader{position: fixed;height: 100%;width: 100%;z-index: 999999999999999;top: 0;left: 0;display: flex;justify-content: center;align-items: center;}/*background-color:rgba(255,255,255,0.3);backdrop-filter: blur(12px);*/
.customLoader .loaderInner{width:82px !important;height:96px !important;position:relative;}
.customLoader .loaderInner .GreenOne, .customLoader .loaderInner .YellowTwo, .customLoader .loaderInner .GreenThree{border-radius:50%;position: absolute;-webkit-animation: spin 2s linear infinite;animation: spin 2s linear infinite;}
.customLoader .loaderInner .GreenOne{background-color:#8cc73b;height:30px !important;width:30px !important;right:0;}
.customLoader .loaderInner .YellowTwo{background-color:#fed700;height:60px !important;width:60px !important;bottom:3px;left:22px;z-index:1;opacity: 0.8;}
.customLoader .loaderInner .GreenThree{background-color:#00a6ae;height:55px !important;width:55px !important;bottom:0;}

@-webkit-keyframes spin {
    0% {
        transform: scale(1,1);
        opacity:1;
    }
    50% {
        transform: scale(1.15,1.15);
        opacity:0.5;
    }
    100% {
        transform: scale(1,1);
        opacity:1;
    }
  }
  
  @keyframes spin {
    0% {
        transform: scale(1,1);
        opacity:1;
    }
    50% {
        transform: scale(1.15,1.15);
        opacity:0.5;
    }
    100% {
        transform: scale(1,1);
        opacity:1;
    }
  }

  .customLoader2{position: fixed;height: 100%;width: 100%;z-index: 999999999999999;top: 0;left: 0;display: flex;justify-content: center;align-items: center;}/*background-color:rgba(255,255,255,0.3);backdrop-filter: blur(12px);*/
  .customLoader2 .loaderInner{width:60px !important;height:60px !important;position:relative;}
  .customLoader2 .loaderInner .GreenOne, .customLoader2 .loaderInner .YellowTwo, .customLoader2 .loaderInner .GreenThree{border-radius:50%;position: absolute;-webkit-animation: spin2 2s linear infinite;animation: spin2 2s linear infinite;}
  .customLoader2 .loaderInner .GreenOne{background-color:#8cc73b;height:30px !important;width:30px !important;right:0;}
  .customLoader2 .loaderInner .YellowTwo{background-color:#fed700;height:60px !important;width:60px !important;bottom:0;left:0;z-index:1;opacity: 0.8;}
  .customLoader2 .loaderInner .GreenThree{background-color:#00a6ae;height:55px !important;width:55px !important;bottom:0;}
    
@-webkit-keyframes spin2 {
    0% {
        transform: scale(0.8,0.8);
        opacity:1;
    }
    50% {
        transform: scale(1,1);
        opacity:0.5;
    }
    100% {
        transform: scale(0.8,0.8);
        opacity:1;
    }
  }
  
  @keyframes spin2 {
    0% {
        transform: scale(0.8,0.8);
        opacity:1;
    }
    50% {
        transform: scale(1,1);
        opacity:0.5;
    }
    100% {
        transform: scale(0.8,0.8);
        opacity:1;
    }
  }